<template>
    <layout-default>
        <div class="box">

            <h1 class="title">Mentoria</h1>
            <div class="columns">
                <div class="column is-half">
                    <h2 class="subtitle">Informações Pessoais</h2>
                    <p><strong>Nome:</strong> Seu Nome</p>
                    <p><strong>Email:</strong> seu.email@example.com</p>
                    <p><strong>Telefone:</strong> (99) 99999-9999</p>
                    <!-- Outras informações pessoais podem ser adicionadas aqui -->
                    <b-image ratio="2by1" src="https://thispersondoesnotexist.com" alt="Pessoa Aleatória"
                        style="max-width: 50%;" />

                </div>

                <div class="column">
                    <h2 class="subtitle">QR Code Compartilhável</h2>
                    Seu QR Code de Mentor(a) <br />
                    <img src="https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=Teste" />
                    <br /><b-button type="is-success" @click="compartilharNoWhatsApp">📞 Compartilhar no WhatsApp</b-button>
                    <b-button type="is-info" @click="compartilharNoLinkedIn">Compartilhar no LinkedIn</b-button>


                </div>
            </div>


            <h2 class="subtitle">Suas atividades como mentor(a)</h2>
            <div class="columns">
                <div class="column">
                    <drop @drop="moverTarefaAFazer">
                        <div class="box">
                            <h2 class="subtitle">A fazer</h2>
                            <div v-if="tarefasParaFazer.length === 0">
                                <li>Nenhuma tarefa a fazer.</li>
                            </div>
                            <div v-else>

                                <article class="message is-black" v-for="tarefa in tarefasParaFazer" :key="tarefa.id">
                                    <drag :data="tarefa">
                                        <div class="message-header">
                                            <p>{{ tarefa.nome }}</p>
                                            <button class="delete" aria-label="delete"></button>
                                        </div>
                                        <div class="message-body"> 
                                                {{ tarefa.nome }}
                                        </div>
                                    </drag>
                                </article>

                            </div>
                        </div>
                    </drop>
                </div>

                <div class="column">
                    <drop @drop="moverTarefaProgresso">
                        <div class="box">
                            <h2 class="subtitle">Em progresso</h2>
                            <div v-if="tarefasEmProgresso.length === 0">
                                <li>Nenhuma tarefa em progresso.</li>
                            </div>
                            <div v-else>

                                <article class="message is-warning" v-for="tarefa in tarefasEmProgresso" :key="tarefa.id">
                                    <drag :data="tarefa">
                                        <div class="message-header">
                                            <p>{{ tarefa.nome }}</p>
                                            <button class="delete" aria-label="delete"></button>
                                        </div>
                                        <div class="message-body is-warning is-light"> 
                                            <br />{{ tarefa.nome }}
                                        </div>
                                    </drag>
                                </article>



                            </div>

                        </div>
                    </drop>
                </div>

                <div class="column"><drop @drop="moverTarefaConcluida">
                        <div class="box">
                            <h2 class="subtitle">Concluído</h2>
                            <div v-if="tarefasConcluidas.length === 0">
                                <li>Nenhuma tarefa concluída.</li>
                            </div>
                            <div v-else>

                                <article class="message is-success" v-for="tarefa in tarefasConcluidas" :key="tarefa.id">
                                    <drag :data="tarefa">
                                        <div class="message-header">
                                            <p>{{ tarefa.nome }}</p>
                                            <button class="delete" aria-label="delete"></button>
                                        </div>
                                        <div class="message-body is-success is-light"> 
                                            <br />{{ tarefa.nome }}
                                        </div>
                                    </drag>
                                </article>



                            </div>

                        </div>
                    </drop>
                     
                </div>
            </div>


            <h2 class="subtitle">Meus Mentorados</h2>
            <div class="columns">

                <div class="column is-half">
                    <h2 class="subtitle">Lista de Mentorados</h2>
                    <ul class="menu-list">
                        <li v-for="mentorado in mentorados" :key="mentorado.id" @click="verInteracoes(mentorado.id)">
                            <b-button rounded type="is-success is-light rounded"
                                :disabled="mentoradoSelecionado == mentorado.id">{{ mentorado.nome }} </b-button>
                        </li>
                    </ul>
                </div>
                <div class="column is-half">
                    <h2 class="subtitle">Últimas Interações</h2>
                    <ul>
                        <li v-for="interacao in ultimasInteracoesFiltered" :key="interacao.id">
                            <strong>{{ interacao.data }}</strong> - {{ interacao.mensagem }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>


    </layout-default>
</template>

<script>

import { Drag, Drop, DropMask } from "vue-easy-dnd";


export default {
    components: { Drag, Drop, DropMask },
    data: function () {
        return {
            mentorados: [
                { id: 1, nome: 'Helen' },
                { id: 2, nome: 'João' },
                { id: 3, nome: 'Raquel' },
                // Adicione mais mentorados conforme necessário
            ],
            ultimasInteracoes: [
                { id: 1, mentoradoId: 1, data: '2023-07-26', mensagem: 'Conclui o projeto X' },
                { id: 2, mentoradoId: 1, data: '2023-07-25', mensagem: 'Reunião sobre desenvolvimento web' },
                { id: 3, mentoradoId: 2, data: '2023-07-24', mensagem: 'Discussão sobre carreira' },
                // Adicione mais interações conforme necessário
            ],
            mentoradoSelecionado: null,
            tarefasParaFazer: [
                { id: 1, nome: 'Tarefa 1', board: "tarefasParaFazer" },
                { id: 2, nome: 'Tarefa 2', board: "tarefasParaFazer" },
                // Adicione mais tarefas conforme necessário
            ],
            tarefasEmProgresso: [],
            tarefasConcluidas: [],
        }
    },
    methods: {
        verInteracoes(mentoradoId) {
            this.mentoradoSelecionado = mentoradoId;
        },
        moverTarefaProgresso(e) {
            this.moverTarefa(e.data, "tarefasEmProgresso");
        },
        moverTarefaAFazer(e) {
            this.moverTarefa(e.data, "tarefasParaFazer");
        },
        moverTarefaConcluida(e) {
            this.moverTarefa(e.data, "tarefasConcluidas");
        },
        moverTarefa(tarefa, destino) {
          
            let origem = tarefa.board;
            tarefa.board = destino;

            let index = this[origem].indexOf(tarefa);
            this[origem].splice(index, 1);


            this[destino].push(tarefa);
            // if (destino === 'Em progresso') {

            // } else if (destino === 'Concluído') {
            //     this.tarefasEmProgresso = this.tarefasEmProgresso.filter(item => item.id !== tarefa.id);
            //     this.tarefasConcluidas.push(tarefa);
            // }else if (destino === 'A Fazer') {
            //     this.tarefasParaFazer = this.tarefasEmProgresso.filter(item => item.id !== tarefa.id);
            //     this.tarefasConcluidas.push(tarefa);
            // }
        },
        compartilharNoWhatsApp() {
            // Aqui você pode definir a mensagem que deseja compartilhar no WhatsApp
            const mensagem = 'Confira esta página incrível! https://www.exemplo.com';

            // Substitua o número de telefone abaixo pelo número que você deseja compartilhar
            const numeroTelefone = '1234567890';

            // Monta o link do WhatsApp com a mensagem
            const url = `https://wa.me/${numeroTelefone}?text=${encodeURIComponent(mensagem)}`;

            // Abre a janela do WhatsApp com o link gerado
            window.open(url, '_blank');
        },
        compartilharNoLinkedIn() {
            // Aqui você pode definir o título e a descrição que deseja compartilhar


            // URL da sua página que deseja compartilhar
            const url = encodeURIComponent('https://AteOFuturo.com.br/');

            // Monta o link do compartilhamento do LinkedIn
            const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=Caixa%20de%20ferramentas%20do%20futuro&summary=Veja%20esta%20ferramenta%20de%20colaboração%20criativa&source=AtéOFuturo`;

            // Abre a janela do LinkedIn com o link gerado
            window.open(linkedinUrl, '_blank');
        },
    },
    computed: {
        ultimasInteracoesFiltered() {
            if (this.mentoradoSelecionado) {
                return this.ultimasInteracoes.filter(interacao => interacao.mentoradoId === this.mentoradoSelecionado);
            }
            return [];
        },
    },

}

</script>