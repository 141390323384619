<template>
    <layout-default>
        <div class="box">
            <b-table  :bordered="true"
              :striped="true"
              :narrowed="true"
              :hoverable="true" :data="mockData">
                 
                    
                        <b-table-column field="Nome" label="Nome" v-slot="props">
        {{ props.row.nome }}</b-table-column>
                        <b-table-column field="Data" label="Data" v-slot="props">
        {{ props.row.data }}</b-table-column>
                        <b-table-column field="Ajudante" label="Ajudante" v-slot="props">
        {{ props.row.ajudante }}</b-table-column>
                        <b-table-column  field="Local" label="Local" v-slot="props">
        {{ props.row.local }}</b-table-column>
                        <b-table-column field="Designação" label="Designação" v-slot="props">
        {{ props.row.tipoDesignacao }}</b-table-column>
                    
                   
               
            </b-table>
<br />
<br />
<br />
            <EstudanteDesignacao :nome="'Clayton Freitas'" ajudante="!rf" :data="'31/12/2023'" local="Sala C"
                tipoDesignacao="Revisita" nomeOutraDesignacao="OUTRA"></EstudanteDesignacao>


        </div>
    </layout-default>
</template> 

<style></style>
<script>
import EstudanteDesignacao from './components/estudante-designacao.vue';
export default {
    data() {
        return {
            mockData: [
                {
                    nome: 'Fulano',
                    data: '2023-01-01',
                    ajudante: 'Ciclano',
                    local: 'Salão Principal',
                    tipoDesignacao: 'Leitura',
                    nomeOutraDesignacao: 'Outra Designação 1',
                    dataUltimaDesignacao: '2023-01-01' 
                },
                {
                    nome: 'Beltrano',
                    data: '2023-02-15',
                    ajudante: 'Deltano',
                    local: 'Sala B', 
                    tipoDesignacao: 'Estudo',
                    nomeOutraDesignacao: 'Outra Designação 2',
                    dataUltimaDesignacao: '2023-01-01' 
                },
                // Adicione mais dados conforme necessário
            ]
        }
    },
    components: { EstudanteDesignacao }
}
</script>