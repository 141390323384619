<template>
    <layout-default>

        <vue-headful title="Até o Futuro - Repositório de Aprendizado" description="Repositório de Aprendizado" />
        <div class=" box  ">
            <div class="row columns">
                <div class="  column is-12">

                    <h1 class="title is-3"> 🧠 Repositório de Aprendizado</h1>
                </div>
            </div>

            <div class=" row columns">

                <div class="box column is-6">
                    <div class="learning-inventory">


                        <p>List what you’ve learned or want to learn in the following categories:</p>

                        <p>You step into a time machine, and you emerge into the future. It could be 10 years, 20, 30,
                            or more. In that future time, you look back at all that you
                            learned—for work, for a hobby, or just for fun and curiosity. What were those topics? What
                            does your future self know that your current self doesn’t?
                            List all of the topics you can think of that you’d like to be able to know or do.</p>
                        <!-- Work Learning Section -->
                        <div class="section">
                            <h3>For Work</h3>
                            <textarea v-model="inventory.aprendizados.aprendizadosTrabalho"
                                placeholder="Enter topics you've learned or want to learn for work..."></textarea>
                        </div>

                        <!-- Hobby Learning Section -->
                        <div class="section">
                            <h3>For Hobbies</h3>
                            <textarea v-model="inventory.aprendizados.aprendizadosHobby"
                                placeholder="Enter topics you've learned or want to learn for hobbies..."></textarea>
                        </div>

                        <!-- Fun and Curiosity Section -->
                        <div class="section">
                            <h3>For Fun and Curiosity</h3>
                            <textarea v-model="inventory.aprendizados.aprendizadosDiversao"
                                placeholder="Enter topics you've learned or want to learn for fun and curiosity..."></textarea>
                        </div>

                        <!-- Save Button -->
                        <button @click="saveInventory">Save Inventory</button>

                        <!-- Display Data (Optional for Debugging or User Feedback) -->
                        <div class="output" v-if="savedInventory">
                            <h4>Saved Learning Inventory</h4>
                            <p><strong>For Work:</strong> {{ savedInventory.aprendizados.aprendizadosTrabalho }}</p>
                            <p><strong>For Hobbies:</strong> {{ savedInventory.aprendizados.aprendizadosHobby }}</p>
                            <p><strong>For Fun and Curiosity:</strong> {{
                                savedInventory.aprendizados.aprendizadosDiversao
                            }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout-default>
</template>

<script>

import firebase from "firebase";
export default {
    data() {
        return {
            inventory: {

                work: '',
                hobby: '',
                fun: '',
            },
            savedInventory: null, // To store saved data and provide feedback
        };
    },

    mounted() {
        this.loadData();
    },
    methods: {
         
        loadData() {
            var thisVM = this;
            var InventarioRef = firebase
                .database()
                .ref(`Users/${thisVM.$store.state.currentUser.uid}/InventarioAprendizado/`);

            thisVM.$store.commit("startLoading");
            InventarioRef.on("value", function (snapshot) {

                thisVM.inventory = snapshot.val();
                //  debugger;
                thisVM.$buefy.toast.open(`Inventário carregado!`);

                thisVM.$store.commit("stopLoading");
            });
        },
        saveInventory() {
            // Save the inventory data (here we just log it and store it locally)
            this.savedInventory = { ...this.inventory };
            console.log('Learning Inventory Saved:', this.savedInventory);
        },
    },
};
</script>

<style scoped>
.learning-inventory {
    font-family: Arial, sans-serif;
    margin: 20px;
}

.section {
    margin-bottom: 20px;
}

textarea {
    width: 100%;
    height: 80px;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

.output {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    background: #f9f9f9;
}
</style>