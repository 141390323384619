<template>
    <div><table class="tg" style="undefined;table-layout: fixed; width: 550px">
<colgroup>
<col style="width: 31px">
<col style="width: 88px"> 
<col style="width: 450px">
<col style="width: 31px">
<col style="width: 27px">
<col style="width: 22px">
<col style="width: 27px">
<col style="width: 27px">
<col style="width: 27px">
<col style="width: 27px">
<col style="width: 21px">
</colgroup>
<thead>
  <tr>
    <th class="tg-0pky"></th>
    <th class="tg-lwcm" colspan="9">DESIGNACÃO PARA A REUNIÃO</th>
    <th class="tg-0pky"></th>
  </tr>
</thead>
<tbody>
  <tr>
    <td class="tg-0pky"></td>
    <td class="tg-lwcm" colspan="9">NOSSA VIDA E MINISTÉRIO CRISTÃO</td>
    <td class="tg-0pky"></td>
  </tr>
  <tr>
    <td class="tg-0pky"></td>
    <td class="tg-fymr">Nome:</td>
    <td class="tg-0pky" colspan="8">{{nome}}</td>
    <td class="tg-0pky"></td>
  </tr>
  <tr>
    <td class="tg-0pky"></td>
    <td class="tg-fymr">Ajudante:</td>
    <td class="tg-0pky" colspan="8">{{ajudante}}</td>
    <td class="tg-0pky"></td>
  </tr>
  <tr>
    <td class="tg-0pky"></td>
    <td class="tg-0pky">Data:</td>
    <td class="tg-0pky" colspan="8">{{data}}</td>
    <td class="tg-0pky"></td>
  </tr>
  <tr>
    <td class="tg-0pky"></td>
    <td class="tg-0pky" colspan="9"></td>
    <td class="tg-0pky"></td>
  </tr>
  <tr>
    <td class="tg-zknr"></td>
    <td class="tg-zknr" colspan="9"><span style="font-weight:inherit;font-style:inherit;color:black">Designacão:</span></td>
    <td class="tg-0pky"></td>
  </tr>
  <tr>
    <td class="tg-n1kq"></td>
    <td class="tg-n1kq" colspan="5"><span style="font-weight:inherit;font-style:inherit;color:black">[ {{isLeitura}} ]Leitura da Bíblia</span></td>
    <td class="tg-n1kq" colspan="4"><span style="font-weight:inherit;font-style:inherit;color:black">[ {{ isEstudo }}]Estudo bíblico</span></td>
    <td class="tg-0pky"></td>
  </tr>
  <tr>
    <td class="tg-n1kq"></td>
    <td class="tg-n1kq" colspan="5"><span style="font-weight:inherit;font-style:inherit;color:black">[ {{ isPrimeiraConversa }}]Primeira conversa</span></td>
    <td class="tg-n1kq" colspan="4"><span style="font-weight:inherit;font-style:inherit;color:black">[{{ isDiscurso }}]Discurso</span></td>
    <td class="tg-0pky"></td>
  </tr>
  <tr>
    <td class="tg-n1kq"></td>
    <td class="tg-n1kq" colspan="1"></td>
    <td class="tg-n1kq" colspan="8"><span style="font-weight:inherit;font-style:inherit;color:black">[ {{isOutra}}]Outra:</span> {{ isOutra=="X"?nomeOutraDesignacao:"" }}</td>
    <td class="tg-0pky"></td>
  </tr>
  <tr>
    <td class="tg-n1kq"></td>
    <td class="tg-n1kq" colspan="5"><span style="font-weight:inherit;font-style:inherit;color:black">[ {{isRevisita}} ]Revisita</span></td>
    <td class="tg-n1kq" colspan="4"></td>
    <td class="tg-0pky"></td>
  </tr>
  <tr>
    <td class="tg-n1kq"></td>
    <td class="tg-n1kq" colspan="9"></td>
    <td class="tg-0pky"></td>
  </tr>
  <tr>
    <td class="tg-n1kq"></td>
    <td class="tg-zknr" colspan="9"><span style="font-weight:inherit;font-style:inherit;color:black">Local:</span></td>
    <td class="tg-0pky"></td>
  </tr>
  <tr>
    <td class="tg-n1kq"></td>
    <td class="tg-n1kq" colspan="9"><span style="font-weight:inherit;font-style:inherit;color:black">[ {{isSalaoPrincipal}} ]Salão principal</span></td>
    <td class="tg-0pky"></td>
  </tr>
  <tr>
    <td class="tg-n1kq"></td>
    <td class="tg-n1kq" colspan="9"><span style="font-weight:inherit;font-style:inherit;color:black">[ {{ isSalaB }}]Sala B</span></td>
    <td class="tg-0pky"></td>
  </tr>
  <tr>
    <td class="tg-n1kq"></td>
    <td class="tg-n1kq" colspan="9"><span style="font-weight:inherit;font-style:inherit;color:black">[ {{ isSalaC }}]Sala C</span></td>
    <td class="tg-0pky"></td>
  </tr>
  <tr>
    <td class="tg-0pky"></td>
    <td class="tg-0pky" colspan="9"></td>
    <td class="tg-0pky"></td>
  </tr>
  <tr>
    <td class="tg-0pky"></td>
    <td class="tg-zknr" colspan="9" rowspan="3"><span style="font-weight:inherit;font-style:inherit;color:black">Observação para o estudante:</span> <span style="font-weight:bold;font-style:inherit;color:black">A lição da brochura Melhore e a fonte de matéria para a sua designação estão na</span><span style="font-weight:bold"> Apostila da Reunião Vida e Ministério</span><span style="font-weight:bold;font-style:inherit;color:black">. Estude a lição da brochura para saber como aplicar o ponto que você vai considerar.</span><br><br><br>S-89-T11/20<br></td>
    <td class="tg-0pky"></td>
  </tr>
  <tr>
    <td class="tg-0pky"></td>
    <td class="tg-n1kq"></td>
  </tr>
  <tr>
    <td class="tg-0pky"></td>
    <td class="tg-0pky"></td>
  </tr>
</tbody>
</table>
         
    </div>
</template>
<style>

.tg  {border-collapse:collapse;border-spacing:0;}
.tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
  overflow:hidden;padding:10px 5px;word-break:normal;}
.tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
  font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
.tg .tg-n1kq{ border-color:inherit;color:#4A4A4A;text-align:left;vertical-align:top}
.tg .tg-0pky{border-color:inherit;text-align:left;vertical-align:top}
.tg .tg-lwcm{ border-color:#ffffff;font-size:18px;font-weight:bold;text-align:center;vertical-align:top}
.tg .tg-fymr{border-color:inherit;font-weight:bold;text-align:left;vertical-align:top}
.tg .tg-zknr{ border-color:inherit;color:#4A4A4A;font-weight:bold;text-align:left;vertical-align:top}</style>
<script>
export default {
    props: { nome: String ,data:String, ajudante:String, local:String,tipoDesignacao:String,nomeOutraDesignacao:String},
    computed:{

        isSalaB(){
           return this.local=="Sala B"?"X":"";
        },
        isSalaC(){
            return this.local=="Sala C"?"X":"";
        },
        isSalaoPrincipal(){
            return this.local=="Salão Principal"?"X":"";
        },
        isLeitura(){
            return this.tipoDesignacao=="Leitura"?"X":"";
        },
        isEstudo(){
            return this.tipoDesignacao=="Estudo"?"X":"";
        },
        isDiscurso(){
            return this.tipoDesignacao=="Discurso"?"X":"";
        },
        isPrimeiraConversa(){
            return this.tipoDesignacao=="Primeira"?"X":"";
        },
        isRevisita(){
            return this.tipoDesignacao=="Revisita"?"X":"";
        },
        isOutra(){
            return this.tipoDesignacao=="Outra"?"X":"";
        }
    }
}
</script>