<template>
  <b-navbar :fixed-top="fixedTop">
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ name: 'Sobre' }">
        <img src="@/assets/Logo.png" alt="Até o Futuro" />
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item
        tag="router-link"
        class="navbar-item"
        :to="{ name: 'Home' }"
      >
        <b-icon icon="home-outline" /> <span>&nbsp;</span>HOME
      </b-navbar-item>
      <!-- <b-navbar-dropdown label="Home">
   
        <b-navbar-item
          tag="router-link"
          class="navbar-item"
          :to="{ name: 'Home' }"
          >Home</b-navbar-item
        >
        <hr class="navbar-divider" />
        <div class="navbar-item">Versão 0.0.1 BETA</div>
      </b-navbar-dropdown> -->
      <b-navbar-item
        tag="router-link"
        :to="{ name: 'AgendarEntrevista' }"
        alt="Faça seu Teste de Empregabilidade e Mentoria Grátis"
        v-if="
          $store.state.currentUser != null &&
          ($store.state.currentUserIsPremium ||
            $store.state.currentUserIsExpert)
        "
      >
        🌐 <span>&nbsp;</span> Teste de Empregabilidade
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ name: 'InventarioAprendizado' }">
        🧠 <span>&nbsp;</span> Inventário de aprendizado</b-navbar-item
      > 
      <!-- <b-navbar-item tag="router-link" :to="{ name: 'FeedbackDashboard' }">
        <b-icon icon="atom" /> <span>&nbsp;</span> Feedbacks</b-navbar-item
      > -->
      <b-navbar-item tag="router-link" :to="{ name: 'Toolbox' }">
        🛠 Caixa de Ferramentas</b-navbar-item
      >
      <b-navbar-item
        tag="router-link"
        :to="{ name: 'InitiativeDashboard' }"
        v-if="
          $store.state.currentUser != null &&
          ($store.state.currentUserIsPremium ||
            $store.state.currentUserIsExpert)
        "
      >
        <b-icon icon="eye-outline" />
        <span>&nbsp;</span> Iniciativas</b-navbar-item
      >

      <b-navbar-dropdown label="Ajuda e Contato" icon="help">
        <b-navbar-item tag="router-link" :to="{ name: 'Sobre' }"
          ><b-icon icon="help" size="is-small" /> <span>&nbsp;&nbsp;</span>Sobre
          o Até o Futuro
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'Contato' }">
          <b-icon icon="email" size="is-small" />
          <span>&nbsp;&nbsp;</span> Contato</b-navbar-item
        >
        <b-navbar-item tag="router-link" :to="{ name: 'Experts' }"
          ><b-icon icon="account-group" size="is-small" />
          <span>&nbsp;&nbsp; &nbsp;&nbsp; </span>Nossa Equipe
        </b-navbar-item>
        <hr class="navbar-divider" />
        <b-navbar-item tag="router-link" :to="{ name: 'Privacidade' }"
          ><b-icon icon="file" size="is-small" />
          <span>&nbsp;&nbsp; &nbsp;&nbsp; </span>Privacidade
        </b-navbar-item>
      </b-navbar-dropdown>
    </template>

    <template slot="end">
      <!-- <b-navbar-item tag="router-link" :to="{ name: 'MeuPerfil' }">Meu Perfil</b-navbar-item> -->
      <b-navbar-item tag="div">
        <!-- <div class="animate__animated animate__tada  animate__slower  animate__repeat-1	">
          <b-tag v-if="$store.state.currentLoginStreak"  v-bind:type="{ 'is-success': $store.state.currentLoginStreak > 0 }" size="is-medium" :title="`Dias de login consecutivos: ${$store.state.currentLoginStreak} dia(s)`"><span v-if="$store.state.currentLoginStreak>0">🔥</span><span v-else>🙄</span>    {{ $store.state.currentLoginStreak }}</b-tag>
        </div> -->
      </b-navbar-item>
      <b-navbar-item tag="div">
        <template v-if="notifications.length > 0">
          <b-dropdown aria-role="list">
            <button
              class="button"
              v-bind:class="{ 'is-primary': notifications.length > 0 }"
              type="button"
              slot="trigger"
              :title="notifications.length + ' notificações'"
            >
              <b-icon icon="bell"></b-icon> <b-icon icon="menu-down"></b-icon>
              <b-tag type="is-danger">{{ notifications.length }}</b-tag>
            </button>

            <b-dropdown-item
              :value="false"
              aria-role="listitem"
              v-for="notification in notifications"
              v-bind:key="notification.id"
              @click="readAndNavigate(notification)"
            >
              <div class="media">
                <b-icon class="media-left" :icon="notification.icon"></b-icon>
                <div class="media-content">
                  <h3>{{ notification.title }}</h3>
                </div>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template v-else>
          <div>
            <b-icon icon="bell-outline" title="Nenhuma notificação"></b-icon>
          </div>
        </template>
        <b-navbar-dropdown
          label="Perfil & Notificações"
          v-show="$store.state.isLogged"
        >
          <b-navbar-item
            tag="router-link"
            class="navbar-item"
            :to="{ name: 'Notificacoes' }"
            >Notificações</b-navbar-item
          >
          <b-navbar-item
            tag="router-link"
            class="navbar-item"
            :to="{ name: 'MeuPerfil' }"
            >Perfil de {{ $store.state.displayName }}</b-navbar-item
          >
          <!-- <hr class="navbar-divider" />  -->
        </b-navbar-dropdown>
        <div class="buttons">
          <!-- <a class="button is-primary">
            <strong>Sign up</strong>
          </a>-->

          <router-link
            v-show="!$store.state.isLogged"
            to="/Login"
            class="button is-light"
            >Log in</router-link
          >

          <router-link
            v-show="$store.state.isLogged"
            to="/Logoff"
            class="button is-warning"
            >Sair</router-link
          >
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>
<script>
import firebase from "firebase";

export default {
  name: "NavBar",
  data() {
    return {
      notifications: [],
    };
  },
  props: {
    fixedTop: { type: Boolean, default: true },
  },
  created() {
    this.setupData();
  },
  mounted: function () {
    // var burger = document.querySelector('.burger');
    // var menu = document.querySelector('#' + burger.dataset.target);
    // const links = menu.querySelectorAll(".navbar-item");
    // links.forEach(link => {
    //   link.addEventListener("click", function() {
    //     burger.classList.remove("is-active");
    //     menu.classList.remove("is-active");
    //   });
    // });
  },
  methods: {
    readAndNavigate(notification) {
      var thisVM = this;
      firebase
        .database()
        .ref(
          `Users/${thisVM.$store.state.currentUser.uid}/Notifications/${notification.id}`
        )
        .update({ read: true });
      if (notification.link.toLowerCase().includes("http")) {
        window.location.href = notification.link;
      } else {
        thisVM.$router.push({
          name: notification.link,
          params: notification.params,
        });
      }
    },
    clearNotifications() {
      this.notifications.splice(0, this.notifications.length);
    },

    setupData() {
      var thisVM = this;
      if (thisVM.$store.state.isLogged) {
        var notificationsRef = firebase
          .database()
          .ref(`Users/${thisVM.$store.state.currentUser.uid}/Notifications`)
          .orderByChild("read")
          .equalTo(false);

        notificationsRef.on("value", function (snapshot) {
          thisVM.clearNotifications();
          snapshot.forEach(function (childSnapshot) {
            var childData = childSnapshot.val();

            if (childSnapshot.key != "_count") {
              thisVM.notifications.push({
                id: childSnapshot.key,

                description: childData.description,
                title: childData.title,
                link: childData.link,
                params: childData.params,
                icon: childData.icon,
              });
            }
          });

          //thisVM.$root.stopLoading();
        });
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.isLogged;
    },
  },
  watch: {
    loggedIn() {
      this.setupData();
    },
    notifications() {
      var thisVM = this;

      thisVM.$store.commit(
        "setNotificationCounter",
        thisVM.notifications.length
      );
    },
  },
};
</script>