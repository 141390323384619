import * as firebase from 'firebase'
import 'firebase/auth'
import 'firebase/firestore'
//import { getVertexAI, getGenerativeModel } from "firebase/vertexai-preview";

// firebase init - add your own config here 
let firebaseConfig = JSON.parse(process.env.VUE_APP_FIREBASE_JSON_CONFIG)

if (!firebase.apps.length) {
  //  thisfbApp = firebase.initializeApp(firebaseConfig);
  firebase.initializeApp(firebaseConfig);
}

//firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.database();
const auth = firebase.auth();
const googleProvider = new firebase.auth.GoogleAuthProvider();


// // Initialize the Vertex AI service
// const vertexAI = getVertexAI(firebaseApp);

// // Initialize the generative model with a model that supports your use case
// // Gemini 1.5 models are versatile and can be used with all API capabilities
// const model = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash" });

// export utils/refs
export {
  db,
  auth, googleProvider
}